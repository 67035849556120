@if (showRestoreOverlay$ | async) {
  <bx-ngs-table-restoring-overlay
    [selectedTable]="(uiIndexStateAndTable$ | async).table"
    [uiIndexState]="(uiIndexStateAndTable$ | async).state"
    [isGraph]="true"
  ></bx-ngs-table-restoring-overlay>
} @else {
  @if (message$ | async; as message) {
    <bx-page-message class="w-100">
      {{ message }}
    </bx-page-message>
  } @else {
    <bx-sankey-plot
      [title]="title$ | async"
      xAxisTitle="data.xAxisTitle"
      yAxisTitle="data.yAxisTitle"
      [data]="data$ | async"
    >
    </bx-sankey-plot>
  }
}
