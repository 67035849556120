@if (showRestoreOverlay$ | async) {
  <bx-ngs-table-restoring-overlay
    [selectedTable]="(uiIndexStateAndTable$ | async).table"
    [uiIndexState]="(uiIndexStateAndTable$ | async).state"
    [isGraph]="true"
  ></bx-ngs-table-restoring-overlay>
} @else {
  @if (data$ | async; as data) {
    @if ((isTable$ | async) === false) {
      <bx-graph-heatmap
        class="codon-distribution"
        [data]="data.data"
        [title]="data.title"
        [xAxisTitle]="data.xAxisTitle"
        [yAxisTitle]="data.yAxisTitle"
        [isWrapped]="isWrapped$ | async"
      >
      </bx-graph-heatmap>
    } @else {
      @if (data$ | async; as data) {
        <bx-client-grid
          class="codon-grid w-100 h-100"
          [columnDefs]="data.data.cols"
          [rowData]="data.data.rows"
        >
        </bx-client-grid>
      }
    }
  }
}
