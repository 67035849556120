import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core.store';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { GraphControlTypeEnum } from '../../../../../features/graphs/graph-sidebar';
import { SelectOption } from '../../../../models/ui/select-option.model';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { ngsGraphActions } from '../../ngs-graph-data-store/ngs-graph-data-store.actions';
import { SankeyPlotComponent } from '../../../../../features/graphs/sankey-plot/sankey-plot.component';
import {
  RegionsOptions,
  SankeyRegionsSelectorComponent,
} from './sankey-regions-selector/sankey-regions-selector.component';
import { FormControl } from '@angular/forms';
import {
  Chart,
  GradientColorObject,
  Point,
  PointLabelObject,
  SankeyNodeObject,
  SeriesSankeyDataLabelsFormatterCallbackFunction,
  SeriesSankeyDataLabelsFormatterContextObject,
  SeriesTooltipOptionsObject,
  SVGElement,
} from 'highcharts';
import { roundForGraph } from '../../../../../shared/utils/rounding';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../../../shared/page-message/page-message.component';
import { GraphSidebarOptionsService } from '../../../../user-settings/graph-sidebar-options/graph-sidebar-options.service';
import { DocumentTableType } from '../../../../../../nucleus/services/documentService/document-table-type';
import {
  isAllSequencesTable,
  isChainCombinationsTable,
  isClusterTable,
} from '../../../table-type-filters';
import { sanitizeDTSTableOrColumnName } from '../../../../../../nucleus/services/documentService/document-service.v1';
import { DocumentTable } from '../../../../../../nucleus/services/documentService/types';
import { DocumentTableStateService } from '../../../../document-table-service/document-table-state/document-table-state.service';
import {
  DocumentTableUIIndexState,
  IndexStateEnum,
} from '../../../../document-table-service/document-table-state/document-table-state';
import { NgsTableRestoringOverlayComponent } from '../../../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';

@Component({
  selector: 'bx-ngs-sankey-plot',
  templateUrl: './ngs-sankey-plot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    SankeyPlotComponent,
    AsyncPipe,
    NgsTableRestoringOverlayComponent,
  ],
})
export class NgsSankeyPlotComponent
  extends NgsBaseGraphComponent<SankeyData[], SankeyPlotComponent>
  implements OnInit
{
  @Input() tablesForDocument: Record<string, DocumentTable>;

  @ViewChild('highcharts-chart', { static: false }) chart: ElementRef<Chart>;
  @ViewChild(SankeyPlotComponent) chartComponent: SankeyPlotComponent;

  private static readonly MAX_REGIONS = 7;
  private static HEAVY_DEFAULTS: string[] = ['Heavy V Gene', 'Heavy CDR3', 'VDJ Region'];
  private static LIGHT_DEFAULTS: string[] = ['Light V Gene', 'Light CDR3', 'VJ Region'];
  initialRegions: string[] = [];
  sankeyLabels: Record<string, SVGElement> = {};
  changeMadeControl$ = this.completeOnDestroy(new BehaviorSubject(true));
  changeMade$: Observable<boolean>;
  regionsControl$ = this.completeOnDestroy(new BehaviorSubject<string[]>([]));
  regions$: Observable<string[]>;
  nextRegions$: Observable<Record<string, string[]>>;
  message$ = this.completeOnDestroy(new BehaviorSubject(null));
  title$: Observable<string>;
  topClustersNumberControl$ = this.completeOnDestroy(new BehaviorSubject<number>(10));
  topClustersNumber$: Observable<number>;
  useChainCombinationsTable$ = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  uiIndexStateAndTable$: Observable<{ state: DocumentTableUIIndexState; table: DocumentTable }>;
  showRestoreOverlay$: Observable<boolean>;

  constructor(
    protected store: Store<AppState>,
    protected graphSidebarOptionsService: GraphSidebarOptionsService,
    private readonly injector: Injector,
    private readonly documentTableStateService: DocumentTableStateService,
  ) {
    super(store, graphSidebarOptionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const table$ = this.useChainCombinationsTable$.pipe(
      map((isChainCombinationsTable) =>
        isChainCombinationsTable
          ? 'DOCUMENT_TABLE_CHAIN_COMBINATIONS'
          : 'DOCUMENT_TABLE_ALL_SEQUENCES',
      ),
    );
    const tableIndexState$ = table$.pipe(
      switchMap((table) => this.documentTableStateService.getUIIndexState(this.documentID, table)),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.uiIndexStateAndTable$ = tableIndexState$.pipe(
      filter((indexState) => indexState.currentIndexState != IndexStateEnum.OPEN),
      withLatestFrom(
        table$.pipe(
          switchMap((table) => this.documentTableStateService.getTable(this.documentID, table)),
        ),
      ),
      map(([state, table]) => ({ state, table })),
    );
    this.showRestoreOverlay$ = tableIndexState$.pipe(
      map((indexState) => indexState.currentIndexState !== IndexStateEnum.OPEN),
      tap((showRestoreOverlay) => {
        if (showRestoreOverlay) {
          this.store.dispatch(ngsGraphActions.setLoading({ id: this.documentID, loading: false }));
        }
      }),
    );
    const isTableDataAvailable$ = tableIndexState$.pipe(
      filter((indexState) => indexState.currentIndexState === IndexStateEnum.OPEN),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.selectedTable$
      .pipe(
        map((tableForGraph) => {
          if (
            !Object.values(this.tablesForDocument).some(
              (tableForGraph) => tableForGraph?.metadata?.tableContentType === 'ChainCombinations',
            )
          ) {
            return false;
          }
          if (tableForGraph.tableType === DocumentTableType.ANNOTATOR_RESULT_CHAIN_COMBINATIONS) {
            return true;
          }
          if (new Set(tableForGraph?.metadata?.chainNamesPossiblyPresent).size > 1) {
            return true;
          }
          return (
            new Set(
              tableForGraph.metadata?.clusters?.clusterGroups?.flatMap((group) =>
                group.regionOrGenes?.map((regionOrGene) => regionOrGene.chain),
              ),
            ).size > 1
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.useChainCombinationsTable$);
    this.initialRegions = this.setInitialRegions();
    this.tableExportEnabled$.next(false);
    this.regions$ = this.regionsControl$.pipe(
      distinctUntilChanged(stringArraysEqual),
      startWith([]),
    );
    this.regionsControl$.next([]);
    this.changeMade$ = this.changeMadeControl$.pipe(distinctUntilChanged());
    this.topClustersNumber$ = this.topClustersNumberControl$.pipe(
      map((ctrl) => Number(ctrl)),
      distinctUntilChanged(),
    );
    this.topClustersNumberControl$.next(10);
    this.applySavedOptions();
    const regionsControlValue = this.regionsControl$.value;
    this.controls$.next([
      {
        name: 'sankeyRegions',
        label: 'Regions',
        type: GraphControlTypeEnum.COMPONENT,
        defaultOption: this.regionsControl$.value,
        component: SankeyRegionsSelectorComponent as Component,
        injector: (form: FormControl) =>
          Injector.create({
            providers: [
              {
                provide: RegionsOptions,
                deps: [],
                useValue: new RegionsOptions(
                  this.getClusterTableNames(),
                  regionsControlValue.length > 0 ? regionsControlValue : this.initialRegions,
                  form,
                  NgsSankeyPlotComponent.MAX_REGIONS,
                ),
              },
            ],
            parent: this.injector,
          }),
      },
      {
        name: 'topClusters',
        label: 'Cluster Limit',
        tooltip: "All other clusters will be grouped under 'other'",
        type: GraphControlTypeEnum.SELECT,
        defaultOption: this.topClustersNumberControl$.value,
        options: [5, 10, 15, 20, 25, 30].map((top) => new SelectOption<number>(`${top}`, top)),
      },
    ]);
    this.regions$ = this.regionsControl$.pipe(
      distinctUntilChanged(stringArraysEqual),
      startWith([]),
    );

    this.regions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((regions) => {
      if (regions.length < 2 || regions.length > NgsSankeyPlotComponent.MAX_REGIONS) {
        this.message$.next(
          `Please select between 2 and ${NgsSankeyPlotComponent.MAX_REGIONS} regions`,
        );
      } else {
        this.message$.next(null);
      }
    });
    this.nextRegions$ = this.regions$.pipe(
      map((regions) =>
        regions.reduce(
          (acc, val) => ({
            ...acc,
            [val]: regions.filter((x) => x !== val),
          }),
          {},
        ),
      ),
    );
    combineLatest([
      this.regions$,
      this.topClustersNumber$,
      this.useChainCombinationsTable$,
      isTableDataAvailable$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(200))
      .subscribe(([regions, topCount, useChainCombinationsTable, _]) => {
        if (regions.length >= 2 && regions.length <= NgsSankeyPlotComponent.MAX_REGIONS) {
          const table = useChainCombinationsTable
            ? 'DOCUMENT_TABLE_CHAIN_COMBINATIONS'
            : 'DOCUMENT_TABLE_ALL_SEQUENCES';
          this.store.dispatch(
            ngsGraphActions.params.options.update({
              id: this.documentID,
              graphId: 'sankey',
              value: {
                options: {
                  regions: [...regions],
                  topCount,
                  table,
                },
              },
            }),
          );
        } else {
          this.store.dispatch(
            ngsGraphActions.params.options.update({
              id: this.documentID,
              graphId: 'sankey',
              value: {
                options: null,
              },
            }),
          );
        }
        this.changeMadeControl$.next(false);
      });
    this.title$ = combineLatest([this.regions$, this.useChainCombinationsTable$]).pipe(
      filter(([regions, _]) => regions.length > 1),
      map(([regions, useChainCombinationsTable]) => {
        const fromTableName = useChainCombinationsTable ? 'Chain Combinations' : 'All Sequences';
        return `Relationship between ${regions.slice(0, regions.length - 1).join(', ')}${
          regions.length > 2 ? ',' : ''
        } and ${regions[regions.length - 1]} clusters<br/>(from ${fromTableName} table)`;
      }),
    );

    this.data$ = this.store.pipe(
      selectDataForNgsDocument<'sankey'>(this.documentID, 'sankey'),
      takeUntil(this.ngUnsubscribe),
      withLatestFrom(this.regions$),
      filter(([data, regions]) => {
        return (
          !!data &&
          Object.keys(data.regionTotals).length > 0 &&
          regions.every((region) => Object.keys(data.regionTotals).includes(region))
        );
      }),
      map(([rawData, regions]) => this.process(rawData, regions)),
      catchError(() => {
        this.message$.next('Failed to generate plot – please try a different set of regions');
        return [];
      }),
    );
    const numRegions = this.regionsControl$.value?.length ?? 0;
    const hasSavedRegions = this.graphIdForSidebar && this.savedControls$.value?.sankeyRegions;
    if (numRegions > 1 || this.initialRegions.length > 1) {
      this.changeMadeControl$.next(true);
      if (numRegions === 0 || !hasSavedRegions) {
        this.regionsControl$.next(this.initialRegions);
      }
    }
  }

  /**
   * @param results
   * @param clusters
   */
  process(
    results: {
      clusterMatrix: Record<string, Record<string, number>>;
      clusterFrequencies: Record<string, number>;
      clusterTotals: Record<string, number>;
      regionTotals: Record<string, number>;
      clusterNames: Record<string, string>;
    },
    clusters: string[],
  ): SankeyData[] {
    const nodes: SankeyNodeData[] = Object.keys(results.clusterFrequencies).map((cluster) => {
      const [region, clusterID] = splitIntoRegionAndID(cluster);
      return {
        id: cluster,
        clusterID: cluster,
        region,
        clusterLabel: clusterID,
        clusterName: results.clusterNames[cluster],
        totalInCluster: results.clusterTotals[cluster],
        totalInRegion: results.regionTotals[region],
        level: clusters.indexOf(region),
      };
    });
    const data: SankeyData['data'] = [];
    const seen: Record<string, boolean> = {};
    for (const { clusterID, region } of nodes) {
      for (const id2 in results.clusterMatrix[clusterID] ?? {}) {
        const [toRegion] = splitIntoRegionAndID(id2);
        if (clusters.indexOf(region) === clusters.indexOf(toRegion) - 1) {
          seen[clusterID] = true;
          seen[id2] = true;
          data.push({
            from: clusterID,
            to: id2,
            weight: results.clusterMatrix[clusterID][id2],
            fromRegion: region,
            toRegion,
            fromClusterTotal: results.clusterTotals[clusterID],
            toClusterTotal: results.clusterTotals[id2],
            fromRegionTotal: results.regionTotals[region],
            toRegionTotal: results.regionTotals[toRegion],
          });
        }
      }
    }
    for (const { clusterID, region } of nodes) {
      if (!seen[clusterID]) {
        data.push({
          from: clusterID,
          to: clusterID,
          fromRegion: region,
          toRegion: region,
          weight: results.clusterTotals[clusterID],
          fromClusterTotal: results.clusterTotals[clusterID],
          toClusterTotal: results.clusterTotals[clusterID],
          fromRegionTotal: results.regionTotals[region],
          toRegionTotal: results.regionTotals[region],
          color: { opacity: 0 },
        });
      }
    }

    return [
      {
        tooltip: {
          headerFormat: null,
          pointFormatter: function (this: Point): string {
            if (!isSankeyLinkObjectWithNodes(this)) {
              return;
            }
            const {
              fromNode,
              toNode,
              fromRegion,
              toRegion,
              weight,
              fromClusterTotal,
              toClusterTotal,
              fromRegionTotal,
              toRegionTotal,
            } = this;
            const fromID = fromNode.id.slice(fromNode.id.lastIndexOf('-') + 1);
            const toID = toNode.id.slice(toNode.id.lastIndexOf('-') + 1);
            const fromOther = (fromNode.id as string).includes('-other');
            const fromName = fromOther ? 'Other clusters' : results.clusterNames[fromNode.id];
            const toOther = (toNode.id as string).includes('-other');
            const toName = toOther ? 'Other clusters' : results.clusterNames[toNode.id];
            const roundedFromFrequency = roundForGraph((weight * 100) / fromClusterTotal);
            const roundedToFrequency = roundForGraph((weight * 100) / toClusterTotal);
            const roundedFromRegionFrequency = roundForGraph((weight * 100) / fromRegionTotal);
            const roundedToRegionFrequency = roundForGraph((weight * 100) / toRegionTotal);
            return `<b>${fromRegion}:</b> ${fromName}
                ${fromOther ? '' : `<br/><b>${fromRegion} ID:</b> ${fromID}`}
                <br/><b>${toRegion}:</b> ${toName}
                ${toOther ? '' : `<br/><b>${toRegion} ID:</b> ${toID}`}
                <br/><b>Frequency in ${fromRegion}-${fromID}:</b> ${roundedFromFrequency}% of ${fromClusterTotal}
                <br/><b>Frequency in ${toRegion}-${toID}:</b> ${roundedToFrequency}% of ${toClusterTotal}
                <br/><b>Frequency in ${fromRegion}:</b> ${roundedFromRegionFrequency}%
                <br/><b>Frequency in ${toRegion}:</b> ${roundedToRegionFrequency}%
                <br/><b>Sequences:</b> ${weight}`;
          },
          nodeFormatter: function (this: SankeyNodeObject) {
            if (!isSankeyNodeObject(this)) {
              return;
            }
            const { clusterID, clusterName, totalInRegion, totalInCluster } = this;
            const isOther = (clusterID as string).includes('-other');
            const region = clusterID.slice(0, clusterID.lastIndexOf('-'));
            const id = clusterID.slice(clusterID.lastIndexOf('-') + 1);
            const name = isOther ? `Other clusters` : clusterName;
            const regionFrequency = roundForGraph((totalInCluster * 100) / totalInRegion);
            return `<b>${region}:</b> ${name}
                    ${isOther ? '' : `<br/><b>${region} ID:</b> ${id}`}
                    <br/><b>Frequency in ${region}:</b> ${regionFrequency}%
                    <br/><b>Sequences:</b> ${totalInCluster}`;
          },
        },
        dataLabels: {
          nodeFormatter: function (
            this: SeriesSankeyDataLabelsFormatterContextObject | PointLabelObject,
          ) {
            const { clusterName, clusterID } = this.point as any;
            return clusterID.includes('-other') ? 'Other' : clusterName;
          },
        },
        events: {
          render: renderCustomLabels(clusters, this.sankeyLabels),
        },
        minLinkWidth: 1,
        nodes,
        data,
        type: 'sankey',
      },
    ];
  }

  onControlsChanged({
    sankeyRegions,
    topClusters,
  }: {
    sankeyRegions: string[];
    topClusters: number;
  }) {
    this.regionsControl$.next([...(sankeyRegions ?? [])]);
    this.topClustersNumberControl$.next(topClusters);
    this.changeMadeControl$.next(true);
  }

  exportAsImage() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
        withLatestFrom(this.pngExportEnabled$, this.regions$),
      )
      .subscribe(([documentName, enabled]) => {
        if (enabled) {
          this.getChartComponent().downloadImage(
            documentName,
            {},
            {
              chart: {
                events: {
                  load: renderCustomLabels(Object.keys(this.sankeyLabels), {}),
                },
              },
            },
          );
        }
      });
  }

  setInitialRegions(): string[] {
    const clusterTableNames = this.getClusterTableNames();
    if (
      NgsSankeyPlotComponent.HEAVY_DEFAULTS.every((region) => clusterTableNames.includes(region))
    ) {
      return NgsSankeyPlotComponent.HEAVY_DEFAULTS;
    } else if (
      NgsSankeyPlotComponent.LIGHT_DEFAULTS.every((region) => clusterTableNames.includes(region))
    ) {
      return NgsSankeyPlotComponent.LIGHT_DEFAULTS;
    } else if (
      NgsSankeyPlotComponent.HEAVY_DEFAULTS.every((region) =>
        clusterTableNames.includes(`Heavy-1: ${region}`),
      )
    ) {
      return NgsSankeyPlotComponent.HEAVY_DEFAULTS.map((region) => `Heavy-1: ${region}`);
    } else {
      return clusterTableNames.slice(0, 3);
    }
  }

  private getClusterTableNames() {
    const useChainCombinations = this.useChainCombinationsTable$.value;
    const tableToUse = Object.values(this.tablesForDocument).find(
      useChainCombinations ? isChainCombinationsTable : isAllSequencesTable,
    );
    return Object.keys(this.tablesForDocument)
      .filter(
        (table) =>
          isClusterTable(this.tablesForDocument[table]) &&
          tableToUse.columns.some(
            (column) =>
              column.name ===
              `${sanitizeDTSTableOrColumnName(this.tablesForDocument[table].displayName)} ID`,
          ),
      )
      .map((table) => this.tablesForDocument[table].displayName);
  }
}

interface SankeyLinkData {
  from: string;
  to: string;
  fromRegion: string;
  toRegion: string;
  weight: number;
  fromClusterTotal: number;
  toClusterTotal: number;
  fromRegionTotal: number;
  toRegionTotal: number;
  color?: { opacity: number };
}

interface SankeyNodeData {
  id: string;
  clusterID: string;
  region: string;
  clusterLabel: string;
  clusterName: string;
  totalInCluster: number;
  totalInRegion: number;
  level: number;
}
export interface SankeyData {
  nodes: SankeyNodeData[];
  minLinkWidth: number;
  data: SankeyLinkData[];
  dataLabels: { nodeFormatter: SeriesSankeyDataLabelsFormatterCallbackFunction };
  events: Record<string, Function>;
  tooltip: SeriesTooltipOptionsObject;
  type: 'sankey';
}

function stringArraysEqual(x: string[], y: string[]) {
  return x.length === y.length && x.every((xi, i) => xi === y[i]);
}

function isChartContainer(
  chartOrContainsChart: Chart | { chart: Chart },
): chartOrContainsChart is { chart: Chart } {
  return 'chart' in chartOrContainsChart;
}

function isSankeyLinkObjectWithNodes<
  T extends Point,
  U extends T & SankeyLinkData & { fromNode: SankeyNodeData; toNode: SankeyNodeData },
>(point: T): point is U {
  return 'fromNode' in point && 'toNode' in point && 'fromRegion' in point && 'toRegion' in point;
}

function isSankeyNodeObject<T extends SankeyNodeObject, U extends T & SankeyNodeData>(
  node: T,
): node is U {
  return (
    'clusterID' in node &&
    'clusterName' in node &&
    'totalInRegion' in node &&
    'totalInCluster' in node
  );
}

function renderCustomLabels(clusters: string[], labels: Record<string, SVGElement>) {
  return function (this: Chart | { chart: Chart }) {
    for (const label in labels) {
      labels[label].destroy();
      delete labels[label];
    }
    const chart = isChartContainer(this) ? this.chart : this;
    for (const region of clusters) {
      if (!labels[region] || Object.keys(labels[region]).length === 0) {
        // we want the left and right sides of each label to have a fading background so that
        // they partially obscure overlapping labels in a nice way
        const fill: GradientColorObject = {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 0,
          },
          stops: [
            // the opacity should only be < 1 right at the edges
            [0, 'rgba(255, 255, 255, 0)'],
            [0.1, 'rgba(255, 255, 255, 1)'],
            [0.9, 'rgba(255, 255, 255, 1)'],
            [1, 'rgba(255, 255, 255, 0)'],
          ],
        } as GradientColorObject;
        labels[region] = chart.renderer
          .label(region, 0)
          .css({ fontSize: '12px', color: 'black', fontWeight: 'bold' })
          .attr({
            stroke: 'rgba(0, 0, 0, 1)',
            fill,
            paddingLeft: 20,
            paddingRight: 20,
            r: 8,
            zIndex: 6,
          })
          .add();
      }
    }
    clusters.forEach((region, i) => {
      const bBox: { width: number; x: number; height: number } = labels[region].getBBox();
      const labelWidth = bBox.width;
      const labelHeight = bBox.height;
      /* set the x coordinate of the label's left side as follows:
       * if it's the first label, put it at the start of the chart
       * if it's the last label, put it at the end of the chart,
       *   offset by the label width so its right side is at the end
       * otherwise, we want the centre of the label to line up with the corresponding node.
       * we can do this by noting that the width of the chart is
       *   (where N is the number of nodes, and each node is 20px wide):
       *   N * 20 + (N - 1) * (space between nodes)
       *  = (N - 1) * (space between nodes + 20) + 20
       * Let S = (N - 1) * (space between nodes + 20).
       * (this is the width of each node plus its outgoing links, up to but excluding the next node)
       * Then the centre of the i-th label (from 0) has to sit at (x coord of left of plot) + i * S + 20/2
       * so the left side has to sit at middle - (label width)/2
       * */
      const x =
        i === 0
          ? 0
          : i === clusters.length - 1
            ? chart.chartWidth - labelWidth
            : chart.plotLeft +
              (i * (chart.plotWidth - 20)) / (clusters.length - 1) +
              (20 - labelWidth) / 2;
      labels[region]['attr']({
        x,
        y: chart.chartHeight - labelHeight - 15, // not principled like the x coord, just a reasonable value
      });
    });
  };
}

function splitIntoRegionAndID(toSplit: string): [prefix: string, suffix: string] {
  const split = toSplit.split('-');
  return [split.slice(0, -1).join('-'), split[-1]];
}
